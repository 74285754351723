@font-face {
  font-family: 'Avenir-Regular';
  src: local('AvenirNext-Regular'),
    url(../../../assets/fonts/AvenirNext-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Avenir-DemiBold';
  src: local('AvenirNext-DemiBold'),
    url(../../../assets/fonts/AvenirNext-DemiBold.woff) format('woff');
}

@font-face {
  font-family: 'AvenirNextCyr-Bold';
  src: local('AvenirNextCyr-Bold'),
    url(../../../assets/fonts/AvenirNextCyr-Bold.woff) format('woff');
}

.container {
  display: flex;
  flex: 1;

  flex-direction: column;
}

.header {
  display: flex;
  flex: 0.2;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background-color: black;
  padding-left: 10vw;
  padding-right: 10vw;
}

.bold-yellow-title {
  color: #fffd00;
  font-family: 'AvenirNextCyr-Bold';
  font-size: 1.3rem;
  margin-top: 0;
}

.body {
  display: flex;
  flex: 0.8;
  align-items: center;

  flex-direction: column;
  background-color: black;
}

.body-card {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgba(31, 31, 31, 1) 100%) !important;
}

.icon {
  padding-top: 5vh;
  height: 15vh;
  width: 15vh;
}

.body-text-div {
  display: flex;
  flex: 0.2;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.body-text {
  font-family: 'AvenirNextCyr-Bold';
  font-size: 1.5rem;
  color: #fffd00;
}

.buttonholder {
  display: flex;
  flex: 0.2;
  align-items: center;
  justify-content: center;
}

.dialog-body {
  display: flex;
  flex-direction: column;
  margin: 1vh;
  padding: 5vh;
  background: linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgba(31, 31, 31, 1) 100%) !important;
  align-items: center;
  justify-content: center;
}

.dialog-text {
  font-family: 'Avenir-DemiBold';
  font-size: 1.2rem;
  color: #fffd00;
}
