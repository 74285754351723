.planBox {
  width: 56vmin;
  margin: 0 auto;
  border: 0.5vmax solid rgb(112, 112, 112);
  border-radius: 2vmax;
  height: 50vmax;
  overflow: hidden;
}

.slideSingle .slick-active .planBox {
  box-shadow: 0px 0px 2vmax #08fd00;
}
