.mapboxgl-ctrl-geocoder {
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  text-align: left;
  background-color: transparent !important;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #cccccc !important;
}

.mapboxgl-ctrl-geocoder--icon-search {
  opacity: 0 !important;
  width: 0 !important;
}

.mapboxgl-ctrl-geocoder--input {
  display: flex;
  flex: 1;
  color: #cccccc !important;
  padding: 0 !important;
  line-height: 0.1rem;
  height: 30px !important;
  background: transparent !important;
  border: none !important;
}
.mapboxgl-ctrl-geocoder--button {
  background: transparent !important;
  border: none !important;
}

.geocoder-input {
  border-radius: 0;
}

.react-geocoder.geocoder-input img {
  font-size: 10px;
  margin-right: 3vh;
}

.react-geocoder.geocoder-input .geocoder-input:focus {
  padding-right: 3.5vh !important;
}
