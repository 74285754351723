.slick-list {
  height: 100%;
  justify-content: center;
}

.Card {
  display: flex;
  flex: 0 0 auto;
  background: #313131;
  border-radius: 20px;
  width: 85% !important;
  height: 79%;
  flex-direction: column;
  text-align: left;
  margin-top: 8px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  margin-bottom: 12px;
}

.slideSingle .slick-active .Card {
  box-shadow: 0px 0px 2vmax #08fd00;
}
