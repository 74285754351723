@font-face {
  font-family: 'Avenir-Regular';
  src: local('AvenirNext-Regular'),
    url(../../../../assets/fonts/AvenirNext-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Avenir-DemiBold';
  src: local('AvenirNext-DemiBold'),
    url(../../../../assets/fonts/AvenirNext-DemiBold.woff) format('woff');
}

@font-face {
  font-family: 'AvenirNextCyr-Bold';
  src: local('AvenirNextCyr-Bold'),
    url(../../../../assets/fonts/AvenirNextCyr-Bold.woff) format('woff');
}

@font-face {
  font-family: 'AvenirNextCyr-UltraLight';
  src: local('AvenirNextCyr-UltraLight'),
    url(../../../../assets/fonts/AvenirNextCyr-UltraLight.woff) format('woff');
}

@font-face {
  font-family: 'SegoeUI-Bold';
  src: local('SegoeUI-Bold'), url(../../../../assets/fonts/SegoeUI-Bold.woff) format('woff');
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safariOnly {
      font-size: '17px';
    }
  }
}

.passtext {
  margin-left: 2.1vw !important;
  margin: 0px;
  font-size: 0.9rem;
  font-family: 'AvenirNextCyr-Bold';
}

.SOS {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 43vh;
  margin-right: 2vh;
}

.expanded-card {
  height: calc(var(--vh, 1vh) * 95) !important;
  /* height: calc(var(--vh, 1vh) * 45) !important; */
  margin-top: calc(var(--vh, 1vh) * 5) !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgba(31, 31, 31, 1) 100%) !important;
  transition: all 0.3s;
}

.expanded-card50 {
  height: calc(var(--vh, 1vh) * 95) !important;
  /* height: calc(var(--vh, 1vh) * 45) !important; */
  margin-top: calc(var(--vh, 1vh) * 55) !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgba(31, 31, 31, 1) 100%) !important;
  transition: all 0.3s;
}

.ccard {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex !important;
  /* flex: 1; */
  justify-content: center;
  /* margin-top: 60vmax; */
  /* height: 45vmax; */
  height: calc(var(--vh, 1vh) * 47);
  width: 100% !important;
  background: linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgba(31, 31, 31, 1) 100%) !important;
  transition: all 0.3s;
}

.cover-card {
  margin-top: calc(var(--vh, 1vh) * 53);
  height: calc(var(--vh, 1vh) * 47);
  display: flex;
  flex-direction: column;
  flex: 1;
  /* height: 78%; */
  transition: all 0.3s;
  background: transparent;
}

.cover-card-small {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex !important;
  /* flex: 1; */
  justify-content: center;
  margin-top: calc(var(--vh, 1vh) * 55);
  height: calc(var(--vh, 1vh) * 45);
  width: 100% !important;
  background: linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgba(31, 31, 31, 1) 100%) !important;
  transition: all 0.3s;
}

.cover-card-small55 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex !important;
  flex: 1;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin-top: calc(var(--vh, 1vh) * 55);
  height: calc(var(--vh, 1vh) * 47);
  /* width: 100% !important; */
  background: linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgba(31, 31, 31, 1) 100%) !important;
  transition: all 0.3s;
  padding: 0px 20px;
  flex-wrap: wrap;
}

.cardContainer {
  display: flex;
  height: calc(var(--vh, 1vh) * 47);
  width: 80%;
  padding-left: 12%;
  margin-right: 12%;
  flex-direction: column;
  justify-content: flex-start;
}

.LocationContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 9.5);
  margin-top: 4px;
}

.mapboxgl-ctrl-geocoder--input .LocationContainer {
  line-height: 2vmax;
  font-size: 17px;
}

.transparent {
  background: transparent !important;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.none {
  display: none !important;
  margin: 0;
  transition: all 0.3s;
}
.some {
  display: flex;
  transition: all 0.3s;
}

p {
  color: white;
  line-height: 1.5;
}

.yText {
  color: #fffd00;
}

.geocoder-input {
  height: calc(var(--vh, 1vh) * 5);
  width: 100%;
  font-size: 17px;
  background: transparent;
  font-family: 'Avenir-Regular';
  color: rgb(181, 179, 179);
  border-bottom: solid 0.1pt rgb(181, 179, 179);
  border-top: none;
  border-left: none;
  border-right: none;
  border-width: 0.1pt;
  padding: 0;
}

.geocoder-input:focus {
  outline: none !important;
}

.location-marker {
  z-index: 2;
}

.results-top {
  position: block;
  display: flex !important;
  flex-direction: column;
  margin-top: 13vh;
}

.results-bottom {
  position: relative;
  display: flex !important;
  flex-direction: column;
  margin-top: 3vh;
}

.react-geocoder-item {
  margin-top: 1vh;
  /* border-bottom: solid 0.1pt rgb(181, 179, 179); */
}

.WhiteH3 {
  font-size: 100%;
  line-height: 1.5;
  color: white;
  font-weight: bold;
  font-family: 'AvenirNextCyr-Bold';
}

.YellowH3 {
  height: calc(var(--vh, 1vh) * 5);
  font-size: 100%;
  color: #fffd00;
  font-weight: bold;
  font-family: 'AvenirNextCyr-Bold';
  margin-top: 10px !important;
  margin-bottom: 5px;
  text-size-adjust: 80%;
  line-height: 1.5;
}

.text-container {
  color: white;
  font-family: 'AvenirNextCyr-Bold';
  font-size: 10pt;
  height: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.markerdiv {
  /* background-image: url('../../../../assets/svg/currentLocation.svg'); */
  /* background-size: 90%;
    background-repeat: no-repeat; */
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 80%;
  min-width: 43vw;
  min-height: 13vw;
  position: absolute;
  top: 0;
  left: 0;
}

.markerdiv-image {
  /* background-image: url('../../../../assets/svg/currentLocation.svg'); */
  /* background-repeat: no-repeat; */
  /* display: flex;
    flex: 1; */
  /* flex-direction: row;
    height: 80%; */
  position: absolute;
  height: 110%;
  width: 110%;
}

.current-marker {
  /* position: absolute;
  top: 5px;
  left: 10px; */
  font-size: 9pt;
  display: flex;
  flex: 0.15;
  padding-left: 3%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Avenir-Regular';
  z-index: 2;
}

.current-marker-icon-div {
  display: flex;
  padding-bottom: 9%;
  flex: 0.15;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
}

.current-marker-location {
  /* position: absolute;
  top: 9px;
  left: 40px; */
  z-index: 2;
  display: flex;
  align-items: center;
  height: 70%;
  justify-content: center;
  flex: 0.65;
  font-size: 2.6vw;
  font-weight: 400;
  font-family: 'Avenir-Regular';
}

.current-marker-time {
  position: absolute;
  top: 8px;
  left: 5px;
  font-size: 9pt;
  align-items: center;
  justify-content: center;
  font-family: 'Avenir-DemiBold';
}

.current-marker-time1 {
  position: absolute;
  top: 19px;
  left: 5px;
  font-size: 9pt;
  align-items: center;
  justify-content: center;
  font-family: 'Avenir-DemiBold';
}

.current-marker-icon {
  /* position: absolute;
  top: 10px;
  left: 140px; */
  height: 11pt;
  width: 11pt;
}

.recent-locations {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-div {
  height: 30pt;
  width: 85%;
  display: flex;
  align-items: center;
  border-radius: 20px;
  display: none;
  background: linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgba(31, 31, 31, 1) 100%) !important;
}

.floating-text {
  margin: 5vw;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10pt;
  font-family: 'Avenir-Regular';
  color: white;
}

.floating-yellow-text {
  font-family: 'Avenir-DemiBold';
  color: rgb(253, 253, 0);
}

.result-subtext {
  font-family: 'Avenir-Regular';
  font-size: 8pt;
}

.MuiPaper-root {
  display: flex;
  color: white !important;
  background: linear-gradient(180deg, rgba(49, 49, 49, 1) 50%, rgba(31, 31, 31, 1) 100%) !important;
}

.driver-name-font {
  font-family: 'SegoeUI-Bold';
  font-weight: bold;
  font-size: 11pt;
}

.rating-font {
  font-family: 'Avenir-Regular';
  font-size: 9pt;
}

.rating-font-yellow {
  font-family: 'Avenir-Regular';
  font-size: 9pt;
  color: rgb(253, 253, 0);
}

.react-geocoder-results {
  height: 200px;
  overflow-y: auto !important;
}
