@font-face {
  font-family: 'Avenir Next';
  src: url('../../assets/fonts/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/AvenirNext-Regular.woff') format('woff'),
    url('../../assets/fonts/AvenirNext-Regular.ttf') format('truetype'),
    url('../../assets/fonts/AvenirNext-Regular.svg#AvenirNext-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../assets/fonts/AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/AvenirNext-DemiBold.woff') format('woff'),
    url('../../assets/fonts/AvenirNext-DemiBold.ttf') format('truetype'),
    url('../../assets/fonts/AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-font {
  font-family: 'Avenir Next !important';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar {
  background-color: #4169e1;
}

.navbar h3 {
  display: inline-block;
  text-align: left;
  padding: 10px;
  color: black;
  text-decoration: none;
}

.navbar a {
  display: inline-block;
  padding: 10px;
  color: #fff;
  text-decoration: none;
}

.page-info {
  padding: 10px;
}

.Current {
  color: #2e8b57;
}

.Completed {
  color: #ff6347;
  text-decoration: line-through;
}

.timeline span {
  color: #ffffff;
  margin-bottom: 3px;
  font-family: Avenir Next;
  font-size: 14px;
}

.timeline {
  display: inline-block;
  /* margin-left: 20px; */
}

.content {
  position: relative;
  padding-left: 25px;
  padding-bottom: 0;
  margin-bottom: 0;
  height: 59px;
  margin-top: 10px;
}

.content svg {
  position: absolute;
  left: -10.4px;
  top: -1px;
}

.green-dot-content:before {
  height: 118%;
  width: 2px;
  content: '';
  position: absolute;
  background: #707070;
  left: 5px;
  bottom: -19px;
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: 10.5vmin;
  --ReactInputVerificationCode-itemHeight: 5.5vmax;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #ffffff;
  font-weight: 500;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: rgb(181, 179, 179);
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: rgb(200, 253, 63);
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

body {
  font-size: 16px !important;
}
input,
select {
  font-size: 100% !important;
}

/*** Styles added to fix the issue with zoom in on iphone ***/
/* iPhone < 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}
