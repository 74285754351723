.menuCardBg {
  position: relative;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  /* padding: 65px 26.5px 5px; */
  min-width: 70vw;
  width: 60vmin;
  background: linear-gradient(to bottom, rgba(49, 49, 49, 1) 0%, rgba(31, 31, 30, 1) 100%);
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  flex: 1;
  overflow: scroll;
  flex-flow: column;
  justify-content: space-between;
  height: calc(var(--vh, 1vh) * 100);
}

.menuCardBg:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 4px;
  background: linear-gradient(0deg, rgba(0, 253, 0, 1) 0%, rgba(255, 253, 0, 1) 30%);
}

.btnClose,
.uploadBtn {
  border: none;
  background: transparent;
  padding: 0;
  text-align: left;
}

.btnClose:focus,
.uploadBtn:focus {
  outline: none;
}

.profileSection {
  display: flex;
  flex: 0.35;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imgUploadSection {
  position: relative;
  margin-bottom: 25px;
}

.imgUploadSection .profile-image {
  width: 10vmax;
  height: 10vmax;
  object-fit: contain;
  border: 2px solid #fff;
  border-radius: 50%;
}

.uploadBtn {
  position: absolute;
  left: 0vmin;
  bottom: 0vmax;
}

.profileName h3 {
  font-family: 'Avenir Next';
  font-weight: 600;
  color: #fffd00;
  text-align: center;
  font-size: 2vmax;
  margin: 0;
}

.profileName h3 span {
  display: block;
  color: #fff;
}

.menuLinks {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  list-style-type: none;
  padding-left: 8vw;
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid rgb(112, 112, 112);
  border-bottom: 1px solid rgb(112, 112, 112);
}

.menuLinks li a {
  color: #ffffff;
  font-size: 2vmax;
  text-decoration: none;
  font-family: 'Avenir Next';
  padding: 9px 0;
  display: block;
}

.menuLinks li a span {
  margin-left: 14px;
  vertical-align: middle;
}

.menuLinks li a svg {
  vertical-align: middle;
}

.copyright {
  display: flex;
  height: 100%;
  /* width: 100%; */
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
  font-family: 'Avenir Next';
  color: #fff;
}

.copyright a {
  color: #787878;
  font-size: 0.6rem;
  text-decoration: underline;
}

.closeNotifContainer {
  display: flex;
  flex: 0.15;
  justify-content: space-between;
  align-items: center;
  margin-left: 8vw;
  margin-right: 8vw;
}

.menu-link-container {
  display: flex !important;
  align-items: center;
}

.linkContainer {
  flex: 1;
  width: 100%;
}

.iconContainer {
  width: 3.5vmax;
  display: flex;
  align-items: center;
  justify-content: center;
}
